/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
@import "~@ng-select/ng-select/themes/default.theme.css";
@import 'bootstrap/scss/bootstrap';
@import './_sweet-alert.scss';

.logo-width {
    width: 120px;
  }
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.float-right{
  float: right;
}
.form-control,
.dataTable-wrapper .dataTable-top .dataTable-search .dataTable-input,
.ng-select .ng-select-container,
select {
  color: #000 !important;
}

.selector-pointer .ng-select-container,
select {
  cursor: pointer !important;
}

.mat-mdc-select .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {
  color: #94A3B8 !important;
}
.grecaptcha-badge.hide-recaptcha {
  display: none !important;
}